import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BackgroundImage01 from "../Images/Background.png";
import logo from "../Images/Logo.png";
import mobile from "../Images/MobilePhoto.png";
import axios from "axios";
import Swal from "sweetalert2";
import { ipConfig } from "../Core/Ipconfig";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircle";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CryptoJS from "crypto-js";

const Registration = () => {
  const navigate = useNavigate();
  const [searchParams]: any = useSearchParams();
  const emailFromQueryParams: any = searchParams.get("email");
  const companyId: any = searchParams.get("companyId");
  const [name, setName]: any = useState("");
  const [password, setPassword]: any = useState("");
  const [confirmPassword, setConfirmPassword]: any = useState("");
  const [isFormValid, setIsFormValid]: any = useState(false);

  const [showPassword, setShowPassword]: any = useState(false);
  const [showConfirmPassword, setShowConfirmPassword]: any = useState(false);

  const [phoneNumber, setPhone]: any = useState("");
  const [phoneOtpFromService, setPhoneOtpFromService]: any = useState<any>("");
  const [phoneOtpEntered, setPhoneOtpEntered]: any = useState("");
  const [phoneOtpStatus, setPhoneOtpStatus]: any = useState("");
  const [isPhoneOtpTimerActive, setIsPhoneOtpTimerActive]: any =
    useState(false);

  const [checkIsPhoneRegistered, setCheckIsPhoneRegistered]: any =
    useState(false);

  const [checkIsEmailRegistered, setCheckIsEmailRegistered]: any =
    useState(false);

  const [email] = useState(emailFromQueryParams);
  const [emailOtpFromService, setEmailOtpFromService]: any = useState<any>("");
  const [emailOtpEntered, setEmailOtpEntered]: any = useState("");
  const [emailOtpStatus, setEmailOtpStatus]: any = useState("");
  const [isEmailOtpTimerActive, setIsEmailOtpTimerActive]: any =
    useState(false);
  const [otpRequestId, setOptRequestId] = useState("");
  const [phoneOtptimer, setPhoneTimer] = useState(30);
  const [emailOtptimer, setEmailTimer] = useState(30);

  function decryptData(encryptedData: any, secretKey: any) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }

  useEffect(() => {
    const isValid =
      name !== "" &&
      phoneNumber !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      password === confirmPassword &&
      emailOtpEntered !== "" &&
      emailOtpFromService === emailOtpEntered &&
      phoneOtpEntered !== "" 
    setIsFormValid(isValid);
  }, [
    name,
    phoneNumber,
    password,
    confirmPassword,
    emailOtpEntered,
    phoneOtpEntered,
    emailOtpFromService,
    phoneOtpFromService,
  ]);

  useEffect(() => {
    let intervalId: any;

    if (phoneOtpStatus === 200 && isPhoneOtpTimerActive) {
      intervalId = setInterval(() => {
        setPhoneTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(intervalId);
            setIsPhoneOtpTimerActive(false);
            return 20;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [phoneOtpStatus, isPhoneOtpTimerActive]);

  useEffect(() => {
    let intervalId: any;

    if (emailOtpStatus === 200 && isEmailOtpTimerActive) {
      intervalId = setInterval(() => {
        setEmailTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(intervalId);
            setIsEmailOtpTimerActive(false);
            return 20;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [emailOtpStatus, isEmailOtpTimerActive]);

  const checkPhoneNumberRegistered = async () => {
    try {
      // console.log("Checking phone number");
      const isPhoneResgisteredResponse = await axios.get(
        `${ipConfig}/user/isnumberregistered?phoneNumber=${phoneNumber}`
      );
      // console.log(isPhoneResgisteredResponse, "res object");
      const res = isPhoneResgisteredResponse?.data?.isExist;
      // console.log(res, "response for phone number");
      setCheckIsPhoneRegistered(res);
    } catch (error) {
      console.log(error, "Error while checking the phone number registered");
    }
  };

  const checkEmailRegistered = async () => {
    try {
      // console.log("Checking phone number");
      const isEmailResgisteredResponse = await axios.get(
        `${ipConfig}/user/isemailregistered?email=${email}`
      );

      // console.log(isEmailResgisteredResponse, "res object");
      const res = isEmailResgisteredResponse?.data?.isExist;
      // console.log(res, "response for phone number");
      setCheckIsEmailRegistered(res);
    } catch (error) {
      console.log(error, "Error while checking the email registered");
    }
  };

  const debounce = (func: any, delay: any) => {
    let debounceTimer: any;
    return function (this: any) {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debouncedCheckPhoneNumberRegistered = debounce(
    checkPhoneNumberRegistered,
    300
  );
  const handlePhoneChange = (event: any) => {
    const inputPhoneNumber = event.target.value;
    const validPhoneNumber = /^\d{0,10}$/;
    if (validPhoneNumber.test(inputPhoneNumber)) {
      setPhone(inputPhoneNumber);
      // console.log(inputPhoneNumber, "phone Number input en");
    }
  };
  useEffect(() => {
    if (phoneNumber.length === 10) {
      debouncedCheckPhoneNumberRegistered();
    } else {
      setCheckIsPhoneRegistered(false);
    }
  }, [phoneNumber, debouncedCheckPhoneNumberRegistered]);

  useEffect(() => {
    checkEmailRegistered();
  }, []);

  const handlePhoneOtpChange = (event: any) => {
    const phoneOtpValue = event.target.value;
    const phoneOtpPattern = /^\d{0,6}$/;
    if (phoneOtpPattern.test(phoneOtpValue)) {
      setPhoneOtpEntered(phoneOtpValue);
    }
  };
  const handleEmailOtpChange = (event: any) => {
    const emailOtpValue = event.target.value;
    const emailOtpPattern = /^\d{0,6}$/;
    if (emailOtpPattern.test(emailOtpValue)) {
      setEmailOtpEntered(emailOtpValue);
    }
  };
  const validateForm = () => {
    setIsFormValid(
      name !== "" &&
        phoneNumber !== "" &&
        password !== "" &&
        confirmPassword !== "" &&
        password === confirmPassword &&
        emailOtpFromService === emailOtpEntered &&
        emailOtpFromService !== "" &&
        emailOtpEntered !== "" &&
        phoneOtpEntered !== ""
    );
  };
  const getPhoneOtp = async () => {
    try {
      // const phoneOtpResponse = { data: { otp: "123456" }, status: 200 };
      const response = await axios.post(`${ipConfig}/user/mobileotp`, null, {
        params: {
          phoneNumber: `91${phoneNumber}`,
        },
      });
      if (response.status === 200) {
        setOptRequestId(response?.data?.requestId);
        setPhoneOtpStatus(response?.status);
        setIsPhoneOtpTimerActive(true);
      }
      // console.log(response, 'response');
      // const secretKey = "superSecretKey";
      // const decriptedData = decryptData(response?.data, secretKey);
      // // console.log(decriptedData,"decrypted data - otp");
      // setPhoneOtpFromService(decriptedData?.data);
      // setPhoneOtpStatus(response?.status);
      // if (response?.status === 200) {
      //   setIsPhoneOtpTimerActive(true);
      // }
    } catch (error) {
      console.error("Error occurred while requesting phone OTP:", error);
      Swal.fire("Error occurred!", "Please try again later", "error");
    }
  };

  const getEmailOtp = async () => {
    try {
      // const emailOtpResponse = { data: { otp: "123456" }, status: 200 };
      const emailOtpResponse = await axios.post(`${ipConfig}/user/emailotp`, {
        email: `${email}`,
      });

      // const emailOtp = emailOtpResponse?.data?.otp;
      const secretKey = "superSecretKey";
      const decriptedData = decryptData(emailOtpResponse?.data, secretKey);
      // console.log(decriptedData,"email service OTP");
      setEmailOtpFromService(decriptedData?.otp);
      setEmailOtpStatus(emailOtpResponse?.status);
      if (emailOtpResponse?.status === 200) {
        setIsEmailOtpTimerActive(true);
      }
    } catch (error) {
      console.error("Error occurred while requesting email OTP:", error);
      Swal.fire("Error occurred!", "Please try again later", "error");
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    validateForm();
    if (isFormValid) {
      const requestData = {
        name,
        email,
        phoneNumber,
        password,
      };

      const apiUrl = `${ipConfig}/user/updateuserregister?companyId=${companyId}`;

      try {
        const response = await axios.put(apiUrl, requestData);

        if (response.status === 200) {
          Swal.fire("User Registered Successfully!", "", "success").then(
            (response: any) => {
              if (!response.isDenied) {
                navigate("/success", {
                  state: {
                    userName: name,
                    userEmail: email,
                    userPhone: phoneNumber,
                  },
                });
              }
            }
          );
        } else if (response.status === 409) {
          Swal.fire("Mobile number already registered!", "", "error");
        } else if (response.status === 403) {
          Swal.fire(
            "Email Entered not invited for registration!",
            "Please enter the valid email id",
            "error"
          );
        } else {
          Swal.fire("Something went wrong!", "Please try again later", "error");
        }
      } catch (error: any) {
        if (error?.response?.status === 400) {
          // console.log("enterd", error?.response?.data?.message);
          Swal.fire(
            "Something went wrong!",
            error?.response?.data?.message,
            "error"
          );
        } else {
          Swal.fire("Error occurred!", "Please try again later", "error");
        }

        console.error("Error occurred while making the API request:", error);
      }
    } else {
      Swal.fire("Please fill out all fields correctly!", "", "error");
    }
  };

  const verifyMobileOtp = async () => {
    try{
      console.log(phoneOtpEntered, "phone otp entered");
      console.log(otpRequestId, "otp request id");
      const response = await axios.post(`${ipConfig}/user/mobileotpverify`, {
        requestId: otpRequestId,
        userOTP: phoneOtpEntered,
      });
      console.log(response, "response while verifying mobile otp");
      if(response.status === 200){
        // console.log("Phone OTP verified successfully");
        setOptRequestId("");
        setPhoneOtpStatus(response?.status);
        setIsPhoneOtpTimerActive(true);
        Swal.fire("OTP Verified successfully", "", "success");
      }
    }catch(error: any){
      console.log(error, "error while verifying mobile otp");
      Swal.fire("Invalid OTP", "Please enter correct OTP", "error");
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
        flexDirection: "row",
        backgroundImage: `url(${BackgroundImage01})`,
        backgroundSize: "cover",
        backgroundPosition: "center ",
      }}
    >
      <Box
        sx={{
          width: 950,
          maxHeight: 800,
          boxShadow: 3,
          display: "flex",
          flexDirection: "row",
          borderRadius: 3,
          backgroundColor: "white",
          "@media (max-width: 999px)": {
            width: 350,
          },
        }}
      >
        {/*Info box starts here*/}
        <Box
          sx={{
            backgroundColor: "#2596be",
            width: 600,
            maxHeight: 800,
            padding: 2,
            display: "flex",
            flexDirection: "column",
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            alignItems: "center",
            justifyContent: "space-around",
            "@media (max-width: 999px)": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "white",
              borderRadius: "10px",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection: "column",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#2596be",
                  borderRadius: "10px",
                  paddingBottom: "8px",
                }}
              >
                <img
                  height={80}
                  width={200}
                  alt="logo"
                  color="blue"
                  src={logo}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  color={"#2596be"}
                  fontSize={{ fontSize: 22, fontWeight: "500" }}
                >
                  Access Tally anytime,
                </Typography>
                <Typography
                  color={"#2596be"}
                  fontSize={{ fontSize: 22, fontWeight: "500" }}
                >
                  anywhere
                </Typography>
              </Box>
            </Box>
            <img src={mobile} height={300} width={280} alt="accosync mobile" />
          </Box>
          <Typography
            color={"white"}
            fontSize={20}
            fontWeight={"400"}
            fontFamily={"monospace"}
          >
            Experience unparalleled efficiency with remote accessibility,
            enabling seamless Tally management at your convenience. Effortlessly
            streamline operations from any location, anytime, ensuring a
            hassle-free and efficient workflow
          </Typography>
        </Box>
        {/*Info box ends here*/}
        {/*Registration box starts here*/}
        <Box
          sx={{
            width: 350,
            maxHeight: 800,
            padding: 3,
            margin: "5px",
            backgroundColor: "White",
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            display: "flex",
            gap: 1,
            border: "3px solid grey",
            // borderWidth: 1,
            // borderColor: "grey",
            // borderStyle: "solid",
            flexDirection: "column",

            "@media (max-width: 999px)": {
              borderRadius: 3,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <img src={logo} height={50} width={150} alt="logo" />
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "sans-serif",
                fontSize: 20,
                color: "grey",
              }}
            >
              Registration Form
            </Typography>
          </Box>
          <FormControl onSubmit={handleSubmit} sx={{ gap: 3 }}>
            <TextField
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              size="small"
              error={name.length === 0 ? true : false}
              helperText={name.length === 0 ? "Name is required" : ""}
            />
            <Box>
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                size="small"
                fullWidth
                disabled
                required
                InputProps={{
                  endAdornment: emailOtpFromService === emailOtpEntered &&
                    emailOtpFromService !== "" &&
                    emailOtpEntered !== "" && (
                      <CheckCircleOutlineIcon style={{ color: "lightGreen" }} />
                    ),
                }}
                error={checkIsEmailRegistered ? true : false}
                helperText={
                  checkIsEmailRegistered ? " Email already registered" : ""
                }
              />
              {emailOtpFromService === emailOtpEntered &&
                emailOtpFromService !== "" &&
                emailOtpEntered !== "" && (
                  <p style={{ color: "lightGreen", margin: 0, fontSize: 12 }}>
                    Email OTP Verified Successfully.
                  </p>
                )}
            </Box>
            {!checkIsEmailRegistered && (
              <Box
                sx={{
                  display:
                    emailOtpFromService === emailOtpEntered &&
                    emailOtpFromService !== "" &&
                    emailOtpEntered !== ""
                      ? "none"
                      : "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: 1,
                  }}
                >
                  {emailOtpStatus === 200 ? (
                    <TextField
                      label="Email OTP"
                      variant="outlined"
                      value={emailOtpEntered}
                      onChange={handleEmailOtpChange}
                      size="small"
                      style={{ maxWidth: "65%" }}
                    />
                  ) : (
                    <Box></Box>
                  )}
                  <Button
                    onClick={getEmailOtp}
                    disabled={!email || isEmailOtpTimerActive}
                    variant="contained"
                  >
                    {isEmailOtpTimerActive
                      ? `00:${
                          emailOtptimer < 10
                            ? `0${emailOtptimer}`
                            : emailOtptimer
                        } s`
                      : "Get OTP"}
                  </Button>
                </Box>
                {emailOtpFromService === emailOtpEntered &&
                emailOtpFromService !== "" &&
                emailOtpEntered !== "" ? (
                  <Typography
                    color={"lightGreen"}
                    fontSize={14}
                    alignSelf="start"
                  >
                    Email OTP Verified Successfully !!!
                  </Typography>
                ) : (
                  <Typography
                    color={"red"}
                    fontSize={14}
                    alignSelf="start"
                    style={{
                      display: !emailOtpEntered ? "none" : "",
                    }}
                  >
                    Email OTP did not match !!!
                  </Typography>
                )}
              </Box>
            )}
            <Box>
              <TextField
                label="Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={handlePhoneChange}
                required
                fullWidth={true}
                size="small"
                disabled={
                  phoneOtpFromService === phoneOtpEntered &&
                  phoneOtpFromService !== "" &&
                  phoneOtpEntered !== ""
                }
                InputProps={{
                  startAdornment: <Typography>+91-</Typography>,
                  endAdornment: phoneOtpFromService === phoneOtpEntered &&
                    phoneOtpFromService !== "" &&
                    phoneOtpEntered !== "" && (
                      <CheckCircleOutlineIcon style={{ color: "lightGreen" }} />
                    ),
                }}
              />
              {checkIsPhoneRegistered && (
                <p style={{ color: "red", margin: 0, fontSize: 12 }}>
                  Phone Number already registered.
                </p>
              )}
              {phoneOtpFromService === phoneOtpEntered &&
                phoneOtpFromService !== "" &&
                phoneOtpEntered !== "" && (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 2,
                    }}
                  >
                    <p style={{ color: "lightGreen", margin: 0, fontSize: 12 }}>
                      Phone OTP Verified.
                    </p>
                    <Box
                      color={"blue"}
                      style={{ paddingRight: 5, cursor: "pointer" }}
                      onClick={() => {
                        setPhoneOtpEntered("");
                        setIsEmailOtpTimerActive(false);
                      }}
                    >
                      Edit
                    </Box>
                  </Box>
                )}
            </Box>
            <Box
              sx={{
                display:
                  (phoneOtpFromService === phoneOtpEntered &&
                    phoneOtpFromService !== "" &&
                    phoneOtpEntered !== "") ||
                  checkIsPhoneRegistered
                    ? "none"
                    : "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 1,
                }}
              >
                {phoneOtpStatus === 200 ? (
                  <TextField
                    label="Phone OTP"
                    variant="outlined"
                    value={phoneOtpEntered}
                    onChange={handlePhoneOtpChange}
                    size="small"
                    style={{ maxWidth: "65%" }}
                    // editable={checkIsPhoneRegistered ? false : true}
                  />
                ) : (
                  <Box></Box>
                )}
                {otpRequestId === "" ? (
                  <Button
                    onClick={getPhoneOtp}
                    disabled={
                      !phoneNumber ||
                      isPhoneOtpTimerActive ||
                      phoneNumber.length < 10
                    }
                    variant="contained"
                  >
                    {/* {isPhoneOtpTimerActive
                      ? `00:${
                          phoneOtptimer < 10
                            ? `0${phoneOtptimer}`
                            : `${phoneOtptimer}`
                        } sec`
                      : "Get OTP"} */}
                      Get OTP
                  </Button>
                ) : (
                  <Button variant="contained" onClick={verifyMobileOtp}>Verify OTP</Button>
                )}
              </Box>
              {/* {phoneOtpFromService === phoneOtpEntered &&
              phoneOtpFromService !== "" &&
              phoneOtpEntered !== "" ? (
                <Typography
                  color={"lightGreen"}
                  fontSize={14}
                  alignSelf="start"
                >
                  Phone OTP Verified Successfully !!!
                </Typography>
              ) : (
                <Typography
                  color={"red"}
                  fontSize={14}
                  alignSelf="start"
                  style={{
                    display: !phoneOtpEntered ? "none" : "",
                  }}
                >
                  Phone OTP did not match !!!
                </Typography>
              )} */}
            </Box>
            
            <TextField
              type={showPassword ? "text" : "password"}
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              label="Confirm Password"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                password !== confirmPassword && password !== "" ? true : false
              }
              helperText={
                password !== confirmPassword && password !== ""
                  ? "Password do not match"
                  : ""
              }
            />
            <Button
              type="submit"
              disabled={!isFormValid}
              onClick={handleSubmit}
              style={{
                backgroundColor: isFormValid ? "#007bff" : "#ccc",
              }}
              variant="contained"
              size="small"
            >
              Register
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default Registration;
