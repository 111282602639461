import "./Navbar.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { ipConfig } from "../Core/Ipconfig";
import { Popover } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import Registration from "../Pages/Registration";
function Navbar() {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const openPopover = Boolean(popoverAnchorEl);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [architecture, setArchitecture] = useState("");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = (navId: any) => {
    setAnchorEl(null);
    const aboutElement = document.getElementById(navId);
    if (aboutElement) {
      aboutElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [downloadURL, setDownloadURL] = useState<any>("");
  const [downloadURL32bit, setDownloadURL32bit] = useState<any>("");
  const fetchConnectorCurrentURL = async () => {
    try {
      const connectorVersion: any = await axios.get(
        `${ipConfig}/currentConnectorVersion/getconnectorversion`
      );
      setDownloadURL(connectorVersion?.data?.data?.downloadURL);
      setDownloadURL32bit(connectorVersion?.data?.data?.["32DownloadUrl"]);
      // const currentConnectorDownloadURL: any =
      //   connectorVersion?.data?.data?.downloadURL;
      // setDownloadURL(currentConnectorDownloadURL);
    } catch (error) {
      console.log("Error fetching connector downloadURL:", error);
    }
  };

  const handleNavigation = () => {
    let navigateTo;
    if (architecture === "32bit") {
      navigateTo = downloadURL32bit;
    } else {
      navigateTo = downloadURL;
    }
    if (navigateTo && typeof navigateTo === "string") {
      // Check if the URL contains a protocol
      const regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      if (regex.test(navigateTo)) {
        // Open the provided URL in a new tab
        window.open(navigateTo, "_blank");
      } else {
        // Treat it as a relative URL and append it to the current base URL
        const currentUrl = window.location.href;
        const baseUrl = currentUrl.split("#")[0]; // Remove any hash fragment
        const absoluteUrl = `${baseUrl}/${navigateTo}`;
        window.open(absoluteUrl, "_blank");
      }
    }
  };
  useEffect(() => {
    let navigated = false;
    if (!navigated) {
      handleNavigation();
      navigated = true;
    }
  }, [architecture]);

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };
  const handlePopoverOpen = (event: any) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    let navigated = false;
    if (!navigated) {
      fetchConnectorCurrentURL();
      navigated = true;
    }
    
  }, []);
  return (
    <div className="NavBarContainer">
      <div className="NavBarLogo">
        <img
          src={require("../Images/Logo.png")}
          className="NavbarLogo"
          alt="logo"
        />
      </div>

      <div className="ButtonSec">
        <div className="" style={{ marginRight: "30px" }}>
          <a
            className="NavbarButtonLight"
            href="#"
            onClick={() => {
              handleClose("Home");
            }}
          >
            Home
          </a>
          <a
            className="NavbarButtonLight"
            href="#"
            onClick={() => {
              handleClose("About");
            }}
          >
            About Us
          </a>
          {/* <a className="NavbarButtonLight" href="#Pricing">
            Pricing
          </a> */}
          <a
            className="NavbarButtonLight"
            href="#"
            onClick={() => {
              handleClose("Fotter");
            }}
          >
            Contact Us
          </a>

          <a className="NavbarButtonLight">
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/gform"
            >
              Partners
            </Link>
          </a>

          {/* <a href="/register" className="NavbarButtonLight">
            Register
          </a>
          <a href="/purchase" className="NavbarButtonLight">
            Purchase
          </a> */}
        </div>
        <div onClick={handlePopoverOpen}>
          <button
            className="NavbarButtonLight ButtonBorder"
            // onClick={() => handleNavigation()}
          >
            TRY FOR FREE
          </button>
        </div>
      </div>
      <div className="dropDownSec">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon style={{ color: "white" }} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose("Home");
            }}
          >
            Home
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose("About");
            }}
          >
            About Us
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose("Pricing");
            }}
          >
            Pricing
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleNavigation();
              setAnchorEl(null);
            }}
          >
            Download
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose("Fotter");
            }}
          >
            contact Us
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/Gform");
            }}
          >
            Partner
          </MenuItem>
        </Menu>
      </div>
      <Popover
        open={openPopover}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "0 0px 4px rgba(0, 0, 0, 0.09)",
          },
        }}
      >
        <MenuItem
          sx={{
            fontSize: "14px",
          }}
          onClick={() => {
            setArchitecture("64bit");
          }}
        >
          Download 64bit/x64.
        </MenuItem>
        <MenuItem
          sx={{ fontSize: "14px" }}
          onClick={() => {
            setArchitecture("32bit");
          }}
        >
          Download 32bit/x86.
        </MenuItem>
      </Popover>
    </div>
  );
}

export default Navbar;
