import axios from "axios";
import Card from "../Components/Card";
import { ipConfig } from "../Core/Ipconfig";
import "./DownloadStructure.css";
import { useEffect, useState } from "react";
import { MenuItem, Popover } from "@mui/material";

function DownloadStructure() {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const openPopover = Boolean(popoverAnchorEl);
  const [architecture, setArchitecture] = useState("");
  const [downloadURL, setDownloadURL] = useState<any>("");
  const [downloadURL32bit, setDownloadURL32bit] = useState<any>("");
  const fetchConnectorCurrentURL = async () => {
    try {
      const connectorVersion: any = await axios.get(
        `${ipConfig}/currentConnectorVersion/getconnectorversion`
      );
      setDownloadURL(connectorVersion?.data?.data?.downloadURL);
      setDownloadURL32bit(connectorVersion?.data?.data?.["32DownloadUrl"]);
      // const currentConnectorDownloadURL: any =
      //   connectorVersion?.data?.data.downloadURL;
      // setDownloadURL(currentConnectorDownloadURL);
    } catch (error) {
      console.log("Error fetching connector downloadURL:", error);
    }
  };

  useEffect(() => {
    fetchConnectorCurrentURL();
  }, []);
  useEffect(() => {
    if (architecture === "64bit") {
      downloadConnector();
    } else {
      downloadConnector();
    }
  }, [architecture]);
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };
  const handlePopoverOpen = (event: any) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const downloadConnector = async () => {
    try {
      let navigateTo;
      if (architecture === "32bit") {
        navigateTo = downloadURL32bit;
      } else {
        navigateTo = downloadURL;
      }
      const regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      if (regex.test(navigateTo)) {
        // Open the provided URL in a new tab
        window.open(navigateTo, "_blank");
        handlePopoverClose();
      } else {
        // Treat it as a relative URL and append it to the current base URL
        const currentUrl = "/";
        const baseUrl = currentUrl.split("#")[0]; // Remove any hash fragment
        const absoluteUrl = `${baseUrl}/${navigateTo}`;
        window.open(absoluteUrl, "_blank");
        handlePopoverClose();
      }
    } catch (error: any) {
      console.log(error, "Error");
      handlePopoverClose();
    }
  };
  return (
    <div className="StructureContainer">
      <div onClick={handlePopoverOpen}>
        <Card
          title="Download Connector"
          content="Download our connector tool for streamlined device integration."
          url={require("../Images/connector.png")}
          isPointer={true}
          // navigateTo={architecture !== "" ? downloadURL : ""}
        />
      </div>
      <Card
        title="Sign Up"
        content="Sign up to unlock a world of possibilities and seamless connectivity."
        url={require("../Images/signup.png")}
      />
      <Card
        title="Sync Data"
        content="Effortlessly synchronize your data across devices for real-time accessibility and seamless collaboration."
        url={require("../Images/syncData.png")}
      />
      <Card
        title="Download Accosync app"
        content="Download Accosync and login with the same credentials."
        url={require("../Images/Accosync.png")}
        navigateTo={
          "https://play.google.com/store/apps/details?id=com.deco_tech.tallymobile"
        }
        disableArrow={true}
      />
      <Popover
        open={openPopover}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "0 0px 4px rgba(0, 0, 0, 0.09)",
          },
        }}
      >
        <MenuItem
          sx={{
            fontSize: "14px",
          }}
          onClick={() => {
            setArchitecture("64bit");
          }}
        >
          Download 64bit/x64.
        </MenuItem>
        <MenuItem
          sx={{ fontSize: "14px" }}
          onClick={() => {
            setArchitecture("32bit");
          }}
        >
          Download 32bit/x86.
        </MenuItem>
      </Popover>
    </div>
  );
}

export default DownloadStructure;
