import React from "react";
import "./Card.css";

const Card = ({
  title,
  content,
  url,
  disableArrow,
  isPointer,
  navigateTo,
}: any) => {
  const handleNavigation = () => {
    if (navigateTo && typeof navigateTo === "string") {
      // Check if the URL contains a protocol
      const regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      if (regex.test(navigateTo)) {
        // Open the provided URL in a new tab
        window.open(navigateTo, "_blank");
      } else {
        // Treat it as a relative URL and append it to the current base URL
        const currentUrl = window.location.href;
        const baseUrl = currentUrl.split("#")[0]; // Remove any hash fragment
        const absoluteUrl = `${baseUrl}/${navigateTo}`;
        window.open(absoluteUrl, "_blank");
      }
    }
  };
  return (
    <div className="StructureCardConatiner">
      <div className="StructureCard" onClick={handleNavigation}>
        <img src={url} className="StructureCardImage" alt="icon" />
        <div className="StructureCardTitle">{title}</div>
        <div className="StructureCardContent">{content}</div>
        {!disableArrow && (
          <img
            src={require("../Images/Downarrow.png")}
            className="DownArrow"
            alt="DownArrow"
          />
        )}
      </div>
      {!disableArrow && (
        <img
          src={require("../Images/RightArrow.png")}
          className="RightArrow"
          alt="rightArrow"
        />
      )}
    </div>
  );
};

export default Card;
