import React from "react";

function Gform() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLScv0nI9hcJsLoZt4wMciVZEsy2QwWNMv_opWiahoaVG-x4QTA/viewform?embedded=true"
        width="640"
        height="988"
        frameBorder="0"
        // marginheight="0"
        // marginwidth="0"
      >
        Loading…
      </iframe>
    </div>
  );
}

export default Gform;
